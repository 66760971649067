import { useEffect } from 'react';
import { zendesk } from 'wa.admin-ui.in-product-scripts';

const useInitZendeskWidget = (data) => {
    useEffect(() => {
        if (data) {
            zendesk(data, null);
        }
    }, [data]);
};

export default useInitZendeskWidget;
