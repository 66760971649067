import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import HelpBadge from './help-badge';
import ChatBadge from './chat-badge';
import { IncomingMessages } from './chat-badge/src/chat-badge';

import { showLiveChatWindow } from '../../../utils/zopim-chat/zopim-api';
import { useChatContext } from '../../../entities/help-widget/context/chat-context-provider';

import useOnHoverSecondary from './hooks/use-on-hover-secondary';

import './help-center-badge.css';

const HelpCenterBadge = ({ helpActive }) => {
    const { chatActive, unread } = useChatContext();

    const secondaryMenuRef = useRef(null);
    const [isSecondaryMenuOpen, setSecondaryMenuOpen] = useState(false);

    const isTrialSetup = window.location.pathname.includes('trial-setup-guide');

    useOnHoverSecondary(secondaryMenuRef, () => setSecondaryMenuOpen(false));

    return (
        <div
            ref={secondaryMenuRef}
            className={`${isTrialSetup ? 'd-none' : 'd-flex'}
       -wa-help-center__badge -wa-help-center-z1 -wa-help-center__bottom-right flex-column align-items-center m-2 mr-4`}
        >
            {chatActive ?
                !helpActive && isSecondaryMenuOpen &&
        (
            <HelpBadge
                className="wa-help-center__show-badge"
                helpActive={helpActive}
                chatActive
            />
        )
                :
                !helpActive &&
        (
            <HelpBadge
                className="wa-help-center__show-badge"
                helpActive={helpActive}
            />
        )}
            <div>
                {!helpActive && chatActive && unread !== 0 &&
          (<IncomingMessages onClick={showLiveChatWindow} onKeyDown={showLiveChatWindow} unread={unread} />)}
                {!helpActive && chatActive &&
          (
              <div
                  onMouseOver={() => setSecondaryMenuOpen(true)}
                  onFocus={() => setSecondaryMenuOpen(true)}
              >
                  <ChatBadge
                      onClick={showLiveChatWindow}
                  />
              </div>
          )}
            </div>
        </div>
    );
};

HelpCenterBadge.propTypes =
  {
      helpActive: PropTypes.bool,
  };

HelpCenterBadge.defaultProps =
  {
      helpActive: false,
  };

export default HelpCenterBadge;