const setChatConfiguration =
  (widgetHandler, startChat, endChat, isUnread, clearUnread, setChatOnline, setChatOffline) => {
      if (typeof window.$zopim !== 'undefined') {
          window.$zopim(() => window.$zopim.livechat.setOnConnected(
              window.$zopim.livechat.hideAll,
          ));
          const isChatting = window.$zopim.livechat.isChatting();
          if (isChatting) {
              startChat();
          }
          window.$zopim(() => window.$zopim.livechat.window.onShow(() => {
              clearUnread();
              widgetHandler();
          }));
          window.$zopim(() => window.$zopim.livechat.setOnStatus((status) => {
              if (status === 'online') {
                  setChatOnline();
              }
              if (status === 'offline') {
                  setChatOffline();
              }
          }));
          window.$zopim(() => window.$zopim.livechat.window.onHide(
              window.$zopim.livechat.hideAll));
          window.$zopim(() => window.$zopim.livechat.setOnChatStart(startChat));
          window.$zopim(() => window.$zopim.livechat.setOnChatEnd(endChat));
          window.$zopim(() => window.$zopim.livechat.setOnUnreadMsgs(isUnread));
      } else {
          setTimeout(
              () =>
                  setChatConfiguration(
                      widgetHandler,
                      startChat,
                      endChat,
                      isUnread,
                      clearUnread,
                      setChatOnline,
                      setChatOffline), 200);
      }
  };

export default setChatConfiguration;
