import React from 'react';
import PropTypes from 'prop-types';
import { faCommentDots } from '@fortawesome/pro-regular-svg-icons';
import { useL10nContext } from 'wa.admin-ui.l10n';
import NavigationButton from '../../../components/navigation-button';
import { showLiveChatWindow } from '../../../../../../../../../../../utils/zopim-chat/zopim-api';
import { useChatContext } from '../../../../../../../../../../../entities/help-widget/context/chat-context-provider';

const Chat = ({ collapsed }) => {
    const { labels } = useL10nContext();
    const { online } = useChatContext();

    return (
        <NavigationButton
            name={labels.NAVIGATION.CHAT.BUTTON_LABEL}
            icon={faCommentDots}
            onClick={showLiveChatWindow}
            collapsed={collapsed}
            disabled={!online}
            disabledText={labels.NAVIGATION.CHAT.DISABLED}
        />
    );
};

export default Chat;

Chat.propTypes =
  {
      collapsed: PropTypes.bool,
  };

Chat.defaultProps =
  {
      collapsed: false,
  };