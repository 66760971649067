import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import './help-center.scss';
import './styles/common.css';

import HelpCenterWidget from './components/help-center-widget';
import HelpCenterBadge from './components/help-center-badge';

import setChatConfiguration from './utils/zopim-chat';
import { useChatContext } from './entities/help-widget/context/chat-context-provider';
import { useSectionContext } from './entities/help-widget/context/section-context-provider';
import { useAssociationContext } from './entities/help-widget/context/association-context-provider';

import useElevio from './utils/elevio';
import sectionKeys from './constants/section-keys';

const HelpCenter = ({ helpActive, handleClose }) => {
    const launcherElement = document.getElementById('launcher');

    if (launcherElement) {
        launcherElement.style.display = 'none';
    }

    const containerRef = useRef(null);

    const { isTrial } = useAssociationContext();

    const {
        startChat,
        endChat,
        isUnread,
        clearUnread,
        setChatOnline,
        setChatOffline,
    } = useChatContext();

    const {
        setActiveArticle,
        updateSectionState,
        openWidget,
        pushState,
        article,
        section,
        stateRef,
    } = useSectionContext();

    const articleRef = useRef(article);
    const sectionRef = useRef(section.key);

    const handleOnElevioLinkClick = useCallback((key, articleId, ref) => {
        if (stateRef.current !== sectionKeys.SECTION_HOME) {
            pushState(key, ref);
        }
        stateRef.current = sectionKeys.SECTION_ELEVIO;
        setActiveArticle(articleId);
        updateSectionState(key);
    }, [pushState, setActiveArticle, stateRef, updateSectionState]);

    useEffect(() => {
        setChatConfiguration(handleClose, startChat, endChat, isUnread, clearUnread, setChatOnline, setChatOffline);
    }, [
        handleClose,
        startChat,
        endChat,
        isUnread,
        clearUnread,
        setChatOnline,
        setChatOffline]);

    useElevio(isTrial, setActiveArticle, handleOnElevioLinkClick, openWidget, articleRef, sectionRef);
    useEffect(() => {
        window.openHelpCenter = openWidget;
    }, [openWidget]);

    return (
        <>
            <CSSTransition
                nodeRef={containerRef}
                in={helpActive}
                mountOnEnter
                timeout={300}
                classNames="waHelpContainer"
            >
                <div
                    ref={containerRef}
                    className="-wa-help-center__main-container -wa-help-center__bottom-right h-100 shadow"
                >
                    <HelpCenterWidget />
                </div>
            </CSSTransition>
            <HelpCenterBadge helpActive={helpActive} />
        </>
    );
};

HelpCenter.propTypes =
  {
      helpActive: PropTypes.bool,
      handleClose: PropTypes.func.isRequired,
  };
HelpCenter.defaultProps =
  {
      helpActive: false,
  };

export default HelpCenter;
